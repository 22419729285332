import React from 'react'
import Preferred from './Preferred'

function Mainindustry() {
  return (
    <div>
        <Preferred/>
    </div>
  )
}

export default Mainindustry