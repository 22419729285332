import React from 'react'
import Banner1 from "../../../../assets/Newhome/team.png";
import "../../../styles/creator/Snewhome/Team/Team.css"
function Team({ scrollToBrandform }) {
  return (
    <>
    <div className='new_home_top_banner team_new_home_main_div'>
        <img src={Banner1} alt="" className='new_home_top_banner_img' />
        <div className='hidden_button' onClick={scrollToBrandform}> </div>
    </div>
    </>
  )
}

export default Team