import React, { useState } from 'react';
import "../../../styles/creator/Snewhome/From/Brandform.css";
import { makeApi } from "../../../../api/callApi.tsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../../utils/loader.jsx";

function Brandform() {
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        contactNumber: "",
        industryType: ""
    });

    const [loading, setLoading] = useState(false); // Loader state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        setLoading(true); // Show loader
        try {
            const response = await makeApi("/api/submit-brand", "POST", formData);
            toast.success("Form submitted successfully!");
            setFormData({ fullName: "", email: "", contactNumber: "", industryType: "" });
        } catch (error) {
            console.error("Error submitting form:", error);
            toast.error("Failed to submit form. Please try again.");
        } finally {
            setLoading(false); // Hide loader
        }
    };

    return (
        <>
            <ToastContainer />
            <div className='main_brandform_div'>
                <div className='main_brandform_div_top'>
                    <div className='main_brandform_div_top_title'>Let’s make your brand the next big thing!</div>
                    <div className='main_brandform_div_top_description'>Ready to amplify your brand's reach? Connect with us today! Whether you’re looking to collaborate with top influencers or explore creative campaign strategies, we’re here to help.</div>
                </div>
                <div className='main_brandform_div_bottom'>
                    <div className='main_brandform_div_bottom_input_div'>
                        <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} placeholder='Full Name' className='main_brandform_div_bottom_input' />
                    </div>
                    <div className='main_brandform_div_bottom_input_div'>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder='Company Email Address' className='main_brandform_div_bottom_input' />
                    </div>
                    <div className='main_brandform_div_bottom_input_div'>
                        <input type="text" name="contactNumber" value={formData.contactNumber} onChange={handleChange} placeholder='Contact Number' className='main_brandform_div_bottom_input' />
                    </div>
                    <div className='main_brandform_div_bottom_input_div'>
                        <input type="text" name="industryType" value={formData.industryType} onChange={handleChange} placeholder='Industry Type' className='main_brandform_div_bottom_input' />
                    </div>
                    <div className='main_brandform_div_bottom_input_div'>
                        <button 
                            className='main_brandform_div_bottom_input_button_new' 
                            onClick={handleSubmit} 
                            disabled={loading} // Disable button while loading
                        >
                            {loading ? "Loading...": "Submit"} 
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Brandform;
